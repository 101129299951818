import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/Helix/Logo.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/Helix/LogoText.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Button.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "bg-[#000000] font-inter min-h-screen w-full flex items-center justify-center flex-col" };
const _hoisted_2 = /* @__PURE__ */ _createElementVNode("h1", { class: "text-[32px] xs:text-[40px] font-bold tracking-wide" }, " Stay Tuned! NFT and AI Coming Soon...\u{1F440} ", -1);
const _hoisted_3 = { class: "flex items-center gap-6 mt-4" };
const _hoisted_4 = { class: "text-base leading-5 font-semibold mt-8 mb-8" };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { href: "https://paralleldex.com/" };
const _hoisted_7 = /* @__PURE__ */ _createElementVNode("span", { class: "font-bold" }, "Go to Paralleldex.com", -1);
export default /* @__PURE__ */ _defineComponent({
  __name: "NFT",
  setup(__props) {
    false && {};
    const countdownFrom = ref(5);
    onMounted(() => {
      setIntervalForNow();
      redirectPage();
    });
    function setIntervalForNow() {
      setInterval(() => {
        countdownFrom.value = countdownFrom.value - 1;
      }, 1e3);
    }
    function redirectPage() {
      setTimeout(() => {
        const link = document.createElement("a");
        link.href = "https://t.co/siBkWnxLW3";
        document.body.appendChild(link);
        link.click();
      }, countdownFrom.value * 1e3);
    }
    return (_ctx, _cache) => {
      const _component_CommonHelixLogo = __nuxt_component_0;
      const _component_CommonHelixLogoText = __nuxt_component_1;
      const _component_AppButton = __nuxt_component_2;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _hoisted_2,
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_CommonHelixLogo, { class: "h-[60px] w-20" }),
          _createVNode(_component_CommonHelixLogoText, { class: "block h-12 text-white" })
        ]),
        _createElementVNode("h3", _hoisted_4, [
          _createTextVNode(" Redirecting to paralleldex.com "),
          _unref(countdownFrom) > 0 ? (_openBlock(), _createElementBlock("span", _hoisted_5, "in " + _toDisplayString(_unref(countdownFrom)), 1)) : _createCommentVNode("", true),
          _createTextVNode(" ... ")
        ]),
        _createElementVNode("a", _hoisted_6, [
          _createVNode(_component_AppButton, {
            xl: "",
            class: "bg-blue-500 text-blue-900 w-60 hover:opacity-60"
          }, {
            default: _withCtx(() => [
              _hoisted_7
            ]),
            _: 1
          })
        ])
      ]);
    };
  }
});
